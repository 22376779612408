
import { defineComponent } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import { avatarStatusVariant } from '@/definitions/_general/_data/avatarStatusVariant'
import { avatarColorVariant } from '@/definitions/_general/_data/avatarColorVariant'
import TmPerson from '@/components/shared/TmPerson.vue'

export default defineComponent({
  components: {
    TmPerson,
    TmAvatar,
  },
  setup() {
    const avatarUrl = 'https://randomuser.me/api/portraits/women/33.jpg'
    const avatarName = 'John Doe'
    const colors = avatarColorVariant
    const sizes = ['xSmall', 'small', 'medium', 'large', 'xLarge']
    const statuses = avatarStatusVariant
    return {
      avatarUrl,
      avatarName,
      colors,
      sizes,
      statuses,
    }
  },
})
